import { contentGridQuartersModifier } from 'src/enums/contentGridQuartersModifier';
import { classNamesOfLayout } from 'src/enums/classNamesOfLayout';
import { LocationInLayoutProvider } from 'src/providers/locationInLayout';
import * as S from './styles';

const defaultProps = {
  left: null,
  top: null,
  right: null,
  innerRef: () => null,
  asChild: false,
  asNestedChild: false,
};

const INIT_COLUMN_COUNT = 1;

const determineQuartersModifier = ({ asChild, asNestedChild, hasLeftSidebar, hasRightSidebar }) => {
  const columnsCount = [asChild, asNestedChild, hasRightSidebar, hasLeftSidebar].reduce(
    (accumulatedColumnsCount, shouldIncreaseColumnCount) => {
      if (shouldIncreaseColumnCount) {
        return accumulatedColumnsCount + 1;
      }
      return accumulatedColumnsCount;
    },
    INIT_COLUMN_COUNT,
  );

  switch (columnsCount) {
    case 1:
      return contentGridQuartersModifier.SINGLE_COLUMN;
    case 2:
      return contentGridQuartersModifier.TWO_COLUMNS;
    case 3:
      return contentGridQuartersModifier.THREE_COLUMNS;
    default:
      return contentGridQuartersModifier.THREE_COLUMNS;
  }
};

export const ContentGrid = ({
  left,
  top,
  right,
  center,
  innerRef,
  asChild,
  asNestedChild,
  ...restProps
} = defaultProps) => {
  const hasLeftSidebar = !!left;
  const hasRightSidebar = !!right;
  const hasTopBar = !!top;

  const mainQuartersModifier = determineQuartersModifier({
    asChild,
    asNestedChild,
    hasLeftSidebar,
    hasRightSidebar,
  });

  return (
    <S.ContentGrid
      hasLeft={hasLeftSidebar}
      hasRight={hasRightSidebar}
      ref={innerRef}
      {...restProps}
      className="content-grid-container"
    >
      {hasTopBar && (
        <LocationInLayoutProvider placement={classNamesOfLayout.TOP}>
          <S.ContentTopWrapper
            className={`${classNamesOfLayout.TOP} ${determineQuartersModifier({
              asChild,
              hasLeftSidebar,
              hasRightSidebar: false,
            })}`}
          >
            {top}
          </S.ContentTopWrapper>
        </LocationInLayoutProvider>
      )}

      <LocationInLayoutProvider placement={classNamesOfLayout.MAIN_COLUMN} size={mainQuartersModifier}>
        <S.ContentCenterWrapper
          className={`${classNamesOfLayout.MAIN_COLUMN} ${mainQuartersModifier}`}
          hasSidebars={hasLeftSidebar || hasRightSidebar}
        >
          {center}
        </S.ContentCenterWrapper>
      </LocationInLayoutProvider>

      {hasLeftSidebar && (
        <LocationInLayoutProvider placement={classNamesOfLayout.LEFT_COLUMN}>
          <S.ContentLeftWrapper className={`${classNamesOfLayout.LEFT_COLUMN} no-print`}>{left}</S.ContentLeftWrapper>
        </LocationInLayoutProvider>
      )}
      {hasRightSidebar && (
        <LocationInLayoutProvider placement={classNamesOfLayout.RIGHT_COLUMN}>
          <S.ContentRightWrapper className={`${classNamesOfLayout.RIGHT_COLUMN} no-print`}>
            {right}
          </S.ContentRightWrapper>
        </LocationInLayoutProvider>
      )}
    </S.ContentGrid>
  );
};

ContentGrid.defaultProps = defaultProps;
