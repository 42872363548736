import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { em } from 'polished';

export const ContentGrid = styled.section`
  ${up('desktop')} {
    display: grid;
    grid-template-columns: ${(p) => (p.hasLeft ? '25%' : 0)} minmax(50%, 100%) ${(p) => (p.hasRight ? '25%' : 0)};
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'lft top top'
      'lft ctr rgt';
  }
`;

export const ContentLeftWrapper = styled.aside`
  ${up('desktop')} {
    grid-area: lft;
    margin-right: ${(p) => em(p.theme.spacing[5])};
  }
`;

export const ContentTopWrapper = styled.aside`
  ${up('desktop')} {
    grid-area: top;
  }
`;

export const ContentCenterWrapper = styled.div`
  margin-bottom: ${(p) => p.hasSidebars && em(p.theme.spacing[5])};
  ${up('desktop')} {
    grid-area: ctr;
    margin-bottom: 0;
  }
`;

export const ContentRightWrapper = styled.aside`
  ${up('desktop')} {
    grid-area: rgt;
    margin-left: ${(p) => em(p.theme.spacing[5])};
  }
`;
