import Head from 'next/head';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import { withDatoCMSPageInMainLayout } from 'src/pagesHOCs/withDatoCMSPage';
import ContentGrid from 'src/components/ContentGrid';
import Content from 'src/components/Content';
import Spacer from 'src/components/Spacer';
import { addPrefixToCategoriesSlug } from 'src/utils/addPrefixToCategoriesSlug';
import { withApollo } from 'src/utils/apolloUtils';
import { defaultLanguage } from 'config/locales';
import { clientTypesMap, clientTypesKeys } from 'src/enums/clientsTypes';
import { breakpointsKeys } from 'src/enums/breakpoints';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';

const CategoriesColumnWithContent = dynamic(() => import('src/components/CategoriesColumnWithContent'));

const defaultProps = {
  body: [],
  bodyTop: [],
  categories: [],
  locale: defaultLanguage,
  coreRoutesInfo: {},
};

const HomePage = ({ body, bodyTop, categories, coreRoutesInfo, locale, user, routingMap } = defaultProps) => {
  const categoryPageSlug = coreRoutesInfo.productsPage.name[locale];
  const categoriesWithTransformSlug = addPrefixToCategoriesSlug(categories, categoryPageSlug);
  const productPageSlug = coreRoutesInfo.productPage.name[locale];
  const productPageLayout = coreRoutesInfo.productPage.layout;
  const brandPageSlug = coreRoutesInfo.brandPage.name[locale];
  const brandPageLayout = coreRoutesInfo.brandPage.layout;
  const productsPageLayout = coreRoutesInfo.productsPage.layout;
  const onnTopHomePageLayout = coreRoutesInfo.onntopHomepage.layout;
  const onnTopHomePageSlug = coreRoutesInfo.onntopHomepage.name[locale];

  const currentBreakpoint = useBreakpointDetector();
  const isMobileView = currentBreakpoint === breakpointsKeys.MOBILE;

  const renderContentGrid = () => {
    return (
      <ContentGrid
        center={
          <Content body={bodyTop} coreRoutesInfo={coreRoutesInfo} locale={locale} user={user} routingMap={routingMap} />
        }
        asChild
      />
    );
  };

  return (
    <>
      <Head>
        <script
          key="schema-website"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
              "@context": "http://schema.org",
              "@type": "WebSite",
              "url": "https://onninen.pl/",
              "potentialAction": {
                  "@type": "SearchAction",
                  "target": "https://onninen.pl/szukaj-produktow?query=/szukaj:{search_term_string}",
                  "query-input": "required name=search_term_string"
                  }
            }`,
          }}
        />
        <script
          type="application/ld+json"
          key="schema-organization"
          dangerouslySetInnerHTML={{
            __html: `// <![CDATA[
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name" : "Onninen sp. z o.o.",
                "url": "https://onninen.pl/",
                "logo": "https://onninen.pl/assets/mc/front/core/images/logo.png",
                "contactPoint" : [{
                "@type" : "ContactPoint",
                "telephone" : "+48 42 676 88 66",
                "contactType" : "sales"
                }],
                "sameAs" : [
                  "https://www.facebook.com/onninen.poland",
                  "https://www.youtube.com/channel/UCR3M5F8WW-1WYOzdeQ25tdg",
                  "https://www.linkedin.com/company/onninen-polska",
                  "https://plus.google.com/u/0/103090239412312938502"
                ]
              }
            // ]]>`,
          }}
        />
      </Head>

      {!isMobileView ? (
        <CategoriesColumnWithContent
          categories={categoriesWithTransformSlug}
          mainContent={renderContentGrid()}
          productsPageLayout={productsPageLayout}
          productPageSlug={productPageSlug}
          productPageLayout={productPageLayout}
          brandPageSlug={brandPageSlug}
          brandPageLayout={brandPageLayout}
          onnTopHomePageLayout={onnTopHomePageLayout}
          onnTopHomePageSlug={onnTopHomePageSlug}
        />
      ) : (
        renderContentGrid()
      )}

      <Spacer />

      <Content body={body} coreRoutesInfo={coreRoutesInfo} locale={locale} user={user} routingMap={routingMap} />
    </>
  );
};

const createRedirectTo = (ctx) => ({ layout, url }) => {
  if (ctx?.asPath === url) return;
  if (typeof window !== 'undefined') {
    Router.push(`/${layout}`, url);
  } else {
    ctx.res.writeHead(302, { Location: url }).end();
  }
};

// TODO: Download when active view is tablet/desktop in another way disable this
HomePage.getInitialProps = async (ctx) => {
  const { locale } = ctx;
  const user = ctx?.user;
  const { coreRoutesInfo } = ctx;
  const userB2bHomePageLayout = coreRoutesInfo?.userB2bHomePage?.layout;
  const userB2bHomePageSlug = coreRoutesInfo?.userB2bHomePage?.name?.[locale];
  const userB2bHomePageUrl = `/${userB2bHomePageSlug}`;
  const userB2CHomePageLayout = coreRoutesInfo?.userB2cHomePage?.layout;
  const userB2CHomePageSlug = coreRoutesInfo?.userB2cHomePage?.name?.[locale];
  const userB2CHomePageUrl = `/${userB2CHomePageSlug}`;

  const redirectTo = createRedirectTo(ctx);

  if (user) {
    switch (clientTypesMap.get(user?.clientType)) {
      case clientTypesKeys.CLIENT_B2B:
        redirectTo({
          layout: userB2bHomePageLayout,
          url: userB2bHomePageUrl,
        });
        break;
      case clientTypesKeys.CLIENT_B2C:
        redirectTo({
          layout: userB2CHomePageLayout,
          url: userB2CHomePageUrl,
        });
        break;
      default:
        break;
    }
  }

  return {
    namespacesRequired: ['global'],
  };
};

HomePage.defaultProps = defaultProps;

export default withApollo(withDatoCMSPageInMainLayout(HomePage));
